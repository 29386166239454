import {
    DataTable,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableToolbar
} from "@carbon/react";
import {useState} from "react";
import {getAlternativeName} from "../../../Utils/chartUtils.js";
import ToolBar from "../../Table/ToolBar/index.js";
import PanelDescription from "../PanelDescription/index.js";
import PanelDescriptionInline from "../PanelDescriptionInline/index.js";

const Index = ({chart}) => {
    const modalId = `modalInfo#${chart.options.id}-${chart.options.type}`
    const [rowState, setRowState] = useState([...chart.data])
    const headers = [...chart.options.headers]
    const title = chart.options.title

    const infoModal = <PanelDescription chart={chart} modalId={modalId}></PanelDescription>

    if (rowState && headers && title)
        return (
            <div className='table-panel'>
                <DataTable rows={rowState} headers={headers} isSortable>
                    {({rows, headers, getHeaderProps, getTableProps}) => (
                        <TableContainer>
                            <PanelDescriptionInline chart={chart} modalId={modalId}></PanelDescriptionInline>
                            <TableToolbar>
                                <ToolBar data={chart.data} notForTable={[]} setState={setRowState}></ToolBar>
                            </TableToolbar>
                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableHeader {...getHeaderProps({header})}>
                                                {getAlternativeName(chart, header.header)}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => (
                                            <TableRow key={row.id}>
                                                {row.cells.map((cell) => (
                                                    <TableCell key={cell.id}>{cell.value}</TableCell>
                                                ))}
                                            </TableRow>
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DataTable>
                {infoModal}
            </div>
        )
    else {
        return (
            <div>

            </div>
        )
    }
}


export default Index
