import {HistogramChart} from "@carbon/charts-react";
import PanelDescription from "../../PanelDescription/index.js";
import PanelDescriptionInline from "../../PanelDescriptionInline/index.js";

const Index = ({chart}) => {
    const modalId = `modalInfo#${chart.options.id}-${chart.options.type}`
    const infoModal = <PanelDescription chart={chart} modalId={modalId}></PanelDescription>

    
    if (chart) {
        
        return (<div>
            <HistogramChart options={chart.options}
                            data={chart.data}></HistogramChart>
            <PanelDescriptionInline chart={chart} modalId={modalId}></PanelDescriptionInline>
            {infoModal}
        </div>)
    } else {
        return <div></div>
    }
}

export default Index
