export class ChartModel {
    get type() {
        return this._type;
    }

    set type(value) {
        this._type = value;
    }


    get options() {
        return this._options;
    }

    set options(value) {
        this._options = value;
    }

    get data() {
        return this._data;
    }

    set data(value) {
        this._data = value;
    }


    get error() {
        return this._error;
    }

    set error(value) {
        this._error = value;
    }

    constructor(type, options, data, error) {

        this._options = options;
        this._data = data;
        this._type = type;
        this._error = error;
    }

    toJson() {
        return {
            type: this.type,
            data: this.data,
            options: this.options,
            error: this.error,
        }
    }

}
