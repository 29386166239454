import {ToastNotification} from '@carbon/react';
import {useDispatch} from "react-redux";
import {removeNotification, updateNotification} from "../../store/reducers/notificationsReducer";

const Index = (props) => {
    const notification = props.notification;
    const dispatch = useDispatch()
    return (
        <ToastNotification
            subtitle={notification.subtitle}
            timeout={notification.timeout}
            title={notification.title}
            kind={notification.kind}
            onCloseButtonClick={closeNotification}
            caption={notification.caption}
        />
    )

    function closeNotification() {
        if (notification.viewed) {
            dispatch(removeNotification(notification))
        } else {
            const nt = {...notification}
            nt.viewed = true
            dispatch(updateNotification(nt))
        }
    }
}

export default Index
