import {getAxiosPrivate} from "./common";

const dashboardRequestURL = "/panel/"
const dashboardPanelsRequestURL = "/dashboard/"

export const dashboardDataRequest = async (panel, store) => {
    return getAxiosPrivate(store)
        .get(`${dashboardRequestURL}?id=${panel.id}&chart_type=${panel.type}`)
}
export const dashboardPanelsRequest = async (store) => {
    return getAxiosPrivate(store)
        .get(dashboardPanelsRequestURL)
}
