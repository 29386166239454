import {Button, ComposedModal, ModalBody, ModalFooter} from "@carbon/react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addModal, closeModal, toggleModal} from "../../../store/reducers/modalsReducer.js";
import {convertToHTML} from "../../../Utils/chartUtils.js";
import ModalStateManager from "../../Modal/index.js";

const Index = ({chart, modalId}) => {
    const dispatch = useDispatch();
    const modal = useSelector((state) => state.modals)
    const [modalState, setModalState] = useState(false)
    const title = chart.options.title
    const user_description = chart.options.user_description

    const col_desc = chart.options.col_desc.map((a, index) => {
        return <div className={'column-info'} key={`${a.column_name}#${index}`}>
            <h5
                className={'column-name'}>{a.column_title ? a.column_title.toUpperCase() : a.column_name.toUpperCase()}</h5>
            <span className={'column-description'}>{convertToHTML(a.description)}</span>
        </div>
    })
    let user_desc = <></>
    if (user_description && user_description !== '') {
        user_desc = <>
            <div className={'user-description-container'}>
                <h5>Notas del usuario</h5>
                {user_description}
            </div>
        </>
    }
    const extras = <div className={'info-container'}>
        <h2>{title}</h2>
        <div className={'tooltip-container'}>
            {chart.options.tooltip}
        </div>
        {user_desc}
        <div className={'column-info-container'}>
            {col_desc}
        </div>
    </div>

    const modalService = ModalStateManager(
        {
            children:
                <>
                    <ComposedModal className={''} open={modalState}
                                   onClose={() => dispatch(closeModal(modalId))} size="md">
                        <ModalBody>
                            {extras}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                kind="primary" onClick={() => {
                                dispatch(toggleModal(modalId))
                            }}>
                                Cerrar
                            </Button>
                        </ModalFooter>
                    </ComposedModal>
                </>
        }
    )

    useEffect(() => {
        dispatch(addModal(modalId))
        let mState = modal.modals.find((m) => m.id === modalId)
        mState = mState ? mState.state : false
        setModalState(() => mState)
    }, [modal])

    return (
        <div>
            {modalService}
        </div>
    )

}


export default Index
