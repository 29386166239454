import { ExperimentalChoroplethChart } from "@carbon/charts-react";
import { toNumber } from "../../../../Utils/chartUtils";
import PanelDescription from "../../PanelDescription/index.js";
import PanelDescriptionInline from "../../PanelDescriptionInline/index.js";
import geoDataNUTS0 from "./europe_LEVL_CODE_0.json";
import geoDataNUTS2 from "./europe_LEVL_CODE_2.json";
import geoDataNUTS3 from "./europe_LEVL_CODE_3.json";

const Index = ({ chart }) => {
    const modalId = `modalInfo#${chart.options.id}-${chart.options.type}`
    const infoModal = <PanelDescription chart={chart} modalId={modalId}></PanelDescription>

    if (chart) {
        chart.data = toNumber(chart)

        var contieneSpain = false;
        var contieneGalicia = false;
        for (var key in chart.data) {
            if (chart.data.hasOwnProperty(key)) {
              if (chart.data[key].name === 'Spain') {
                contieneSpain = true;
                break; // Si encuentras 'Spain', puedes salir del bucle
              }else if(chart.data[key].name === 'Galicia') {
                contieneGalicia = true;
                break; // Si encuentras 'Galicia', puedes salir del bucle
              }
            }
        }
        if (contieneSpain){chart.options.geoData = geoDataNUTS0 }
        else if (contieneGalicia){chart.options.geoData = geoDataNUTS2 }
        else{chart.options.geoData = geoDataNUTS3 }
    
    
        return (<div>
            <ExperimentalChoroplethChart
                data={chart.data}
                options={chart.options}
            ></ExperimentalChoroplethChart>

            <PanelDescriptionInline chart={chart} modalId={modalId}></PanelDescriptionInline>
            {infoModal}
        </div>)
    } else {
        return <div></div>
    }
}

export default Index
