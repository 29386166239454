import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {dashboardDataRequest, dashboardPanelsRequest} from '../../apiClient/dashboardRequests';

export const dashboardDataRequestThunk = createAsyncThunk(
    'dashboard/data',
    async (panel, thunkAPI) => {
        try {
            let res = await dashboardDataRequest(panel, thunkAPI);
            thunkAPI.loading = true
            return thunkAPI.fulfillWithValue(res.data)
        } catch (err) {
            console.error(err)
            // hidrate error information on failed request
            return thunkAPI.rejectWithValue({
                http_status_code: err.response?.status
            })
        }
    }
)
export const dashboardOptionsRequestThunk = createAsyncThunk(
    'dashboard/panels',
    async (_, thunkAPI) => {
        try {
            let res = await dashboardPanelsRequest(thunkAPI);
            thunkAPI.loadingDashboard = true
            return thunkAPI.fulfillWithValue(res.data)
        } catch (err) {
            console.error(err)
            // hidrate error information on failed request
            return thunkAPI.rejectWithValue({
                http_status_code: err.response?.status
            })
        }
    }
)

export const dashboardReducer = createSlice({
    name: 'dashboard',
    initialState: {
        data: [],
        panels: [],
        currentPanel: '',
        loading: false,
        loadingDashboard: false,
        error: '',
        currentRequestId: '',
    },
    reducers: {
        resetPanels: (state) => {
            state.panels = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(dashboardDataRequestThunk.pending, (state, action) => {
            if (!state.loading) {
                state.loading = true
                state.currentRequestId = action.meta.requestId
            }
        })
        builder.addCase(dashboardDataRequestThunk.fulfilled, (state, action) => {
            state.data = [action.payload.data]
            state.error = ''
            state.loading = false
        })
        builder.addCase(dashboardDataRequestThunk.rejected, (state) => {
            state.loading = false
            state.error = 'Rejected'
        })
        builder.addCase(dashboardOptionsRequestThunk.pending, (state, action) => {
            if (!state.loading) {
                state.loadingDashboard = true
                state.currentRequestId = action.meta.requestId
            }
        })
        builder.addCase(dashboardOptionsRequestThunk.fulfilled, (state, action) => {
            state.panels = action.payload
            state.error = ''
            state.loadingDashboard = false
        })
        builder.addCase(dashboardOptionsRequestThunk.rejected, (state) => {
            state.loadingDashboard = false
            state.error = 'Rejected'
        })
    }
})

// Action creators are generated for each case reducer function
export const {resetPanels} = dashboardReducer.actions
export default dashboardReducer.reducer
