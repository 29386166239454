import { createSlice } from '@reduxjs/toolkit'


export const modalsReducer = createSlice({
    name: 'notification',
    initialState: {
        modals: [],
    },
    reducers: {
        addModal: (state, action) => {
            if (state.modals.find((m) => m.id === action.payload) === undefined)
                state.modals = [...state.modals, { state: false, id: action.payload }]
        },
        openModal: (state, action) => {
            state.modals = state.map((m) => {
                if (m.id === action.payload) {
                    m.state = true
                }
                return m
            })
        },
        closeModal: (state, action) => {
            state.modals = state.modals.map((m) => {
                if (m.id === action.payload) {
                    m.state = false
                }
                return m
            })
        },
        toggleModal: (state, action) => {
            state.modals = state.modals.map((m) => {
                if (m.id === action.payload) {
                    m.state = !m.state
                }
                return m
            })
        },

    }
})

export const { openModal, closeModal, toggleModal, addModal } = modalsReducer.actions
export default modalsReducer.reducer
