import {BoxplotChart} from "@carbon/charts-react";
import {toNumber} from "../../../../Utils/chartUtils";
import PanelDescription from "../../PanelDescription/index.js";
import PanelDescriptionInline from "../../PanelDescriptionInline/index.js";

const Index = ({chart}) => {
    const modalId = `modalInfo#${chart.options.id}-${chart.options.type}`
    const infoModal = <PanelDescription chart={chart} modalId={modalId}></PanelDescription>


    if (chart) {
        chart.data = toNumber(chart)
        
        return (<div>
            <BoxplotChart options={chart.options}
                          data={chart.data}></BoxplotChart>
            <PanelDescriptionInline chart={chart} modalId={modalId}></PanelDescriptionInline>
            {infoModal}
        </div>)
    } else {
        return <div></div>
    }
}

export default Index
