import {configureStore} from '@reduxjs/toolkit'
import datoolReducer from "./reducers/DAToolReducer";
import userReducer from "./reducers/userReducer"
import dashboardReducer from './reducers/dashboardReducer'
import notificationsReducer from "./reducers/notificationsReducer";
import modalsReducer from "./reducers/modalsReducer";

const store = configureStore({
    reducer: {
        user: userReducer,
        dashboard: dashboardReducer,
        notifications: notificationsReducer,
        modals: modalsReducer,
        datool: datoolReducer
    }
})

// Can still subscribe to the store
//store.subscribe(() => console.log(store.getState()))
export default store
