import { ComboBox, FilterableMultiSelect, Select, SelectItem, TextInput } from "@carbon/react";
import { useEffect, useRef } from "react";
import validator from "validator";
import * as XLSX from 'xlsx';
import { v4 } from "uuid";
import { NotificationModel } from "../Models/Notification.js";
import { sourcesActionsRequestThunk } from "../store/reducers/DAToolReducer.js";
import { toggleModal } from "../store/reducers/modalsReducer.js";
import { pushNotification } from "../store/reducers/notificationsReducer.js";

/**
 * Function that returns previous value from useState
 * @param {*} value
 * @returns {*}
 */
export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

/**
 * Function that returns boolean comparing the current val and previous
 * @param {*} val
 * @returns {boolean}
 */
export const useHasChanged = (val) => {
    const prevVal = usePrevious(val)
    return prevVal !== val
}

/**
 * Function that returns if object is empty
 * @param {Object} obj
 * @returns {boolean}
 */
export const objectIsEmpty = (obj) => Object.keys(obj).length === 0;

/**
 * Function that returns sanitized object for form inputs
 * @param {Object} item - Object to sanitize
 * @returns {Object} - Sanitized object
 */
export function sanitizeItemInputs(item) {
    let sanitized_item = { ...item }
    for (const itemElement in sanitized_item) {
        if (typeof sanitized_item[itemElement] === 'object') {
            for (const inner in sanitized_item[sanitized_item]) {
                if (sanitized_item[itemElement][inner] === null) {
                    sanitized_item[itemElement][inner] = ''
                }
            }
        } else {
            if (sanitized_item[itemElement] === null) {
                sanitized_item[itemElement] = ''
            }
        }
    }
    return sanitized_item
}

/**
 * Function that returns the given date transformed to datepicker compliant string
 * @param {Date} date - Date to transform
 * @returns {string} - Datepicker compliant string
 */
export function toDatePickerString(date) {
    let localdate = date
    if (typeof date !== 'string') {
        if (!date) {
            date = new Date()
        }
        localdate = new Date(date)
        localdate = localdate.toLocaleDateString()
        localdate = localdate.split('/')
        localdate[0] = localdate[0].length > 1 ? localdate[0] : '0' + localdate[0]
        localdate[1] = localdate[1].length > 1 ? localdate[1] : '0' + localdate[1]
        /*localdate = localdate.reverse()*/
        localdate = localdate.join('-')
    }
    return localdate
}

/**
 * Function that returns database stored date to datepicker compliant string
 * @param {Date} date - Database stored date
 * @returns {string} - Datepicker compliant string
 */
export function backendToDatePickerString(date) {
    let localdate = date
    localdate = localdate.split('-')
    localdate = localdate[2] + '/' + localdate[1] + '/' + localdate[0]
    return localdate
}

/**
 * Function that returns a modal from the global modals array
 * Is used to retrieve modal state
 * @param {ref} modal - Store ref to modals
 * @param {string} id - Id of the required modal
 * @returns {Modal} - Matching modal
 */
export function getModalById(modal, id) {
    return modal.modals.find((m) => m.id === id)
}

/**
 * Function that returns a DateTime string from given Date
 * @param {Date} date
 * @returns {string} - DateTime string
 */
export function toDateTime(date) {
    if (!date.includes('/')) {
        return date + ''
    }

    let localdate = date.split('T')
    let localtime = localdate[1]
    if (!localtime) {
        localtime = '00:00'
    }
    localdate = localdate[0].split('/')
    localdate = localdate[2] + '-' + localdate[1] + '-' + localdate[0] + 'T' + localtime
    return localdate
}

/**
 * Function that returns database stored DataSource to local
 * @param {Object} dataSource - Database stored DataSource
 * @param {string} type - DataSource type
 * @returns {Object} - DataSource with its corresponding type
 */
export function dataSourceToLocal(dataSource, type) {
    const localState = { ...dataSource }
    localState.global_type = type
    return localState
}

/**
 * Function that returns a DataSource
 * @param {Object} localState - LocalState (form styled) of the HTTPDownloader
 * @returns {Object} - database compliant HTTPDownloader
 */
export function toHTTPDownloader(localState) {
    const dataSource = { ...dataSources }
   
    dataSource.id = localState.id ? localState.id : ''
    dataSource.name = localState.name
    dataSource.url = localState['url@url']
    delete dataSource['url@url']
    dataSource.file_type = localState.file_type
    dataSource.periodicity = {
        start_date: toDateTime(localState.periodicity.start_date), interval: localState.periodicity.interval

    }
    return dataSource
}

/**
 * Function that returns a SanitizePhase
 * @param localState - LocalState (form styled) of the JoinPhase
 * @returns {{columns: string, output_column: string, id: string, tempId: string, type: string, global_type: string, order: string, output_column: string, add_modified_indicator_column: string}}
 */
export function toSanitizePhase(localState) {
    const phase = { ...sanitizePhase }
    phase.id = localState.id ? localState.id : ''
    phase.name = localState.name ? localState.name : ''
    phase.tempId = localState.tempId ? localState.tempId : ''
    phase.sanitization_type = localState.sanitization_type
    phase.parameters = localState.parameters
    phase.target = localState.target
    phase.target_column = localState.target_column
    phase.output_column = localState.output_column
    phase.add_modified_indicator_column = localState.add_modified_indicator_column
    phase.order = localState.order
    return phase
}

/**
 * Function that returns a KddPhase
 * @param localState - LocalState (form styled) of the JoinPhase
 * @returns {{id: string, name: string, tempId: string, kdd_type: string, output_column: string, input_column: string, group_column: string, order: string}}
 */
export function tokddPhase(localState) {
    const phase = { ...kddPhase }
    phase.id = localState.id ? localState.id : ''
    phase.name = localState.name ? localState.name : ''
    phase.tempId = localState.tempId ? localState.tempId : ''
    phase.kdd_type = localState.kdd_type
    phase.output_column = localState.output_column
    phase.input_column = localState.input_column
    phase.group_column = localState.group_column
    phase.order = localState.order
    phase.parameters_kdd = localState.parameters_kdd
    return phase
}

/**
 * Function that returns a JoinPhase
 * @param localState - LocalState (form styled) of the JoinPhase
 * @returns {{name: string, periodicity: {interval: string, start_date: string}, id: string, type: string, global_type: string}} - database compliant JoinPhase
 */
export function toJoinPhase(localState) {
    const phase = { ...joinPhase }
    phase.id = localState.id ? localState.id : ''
    phase.tempId = localState.tempId ? localState.tempId : ''
    phase.name = localState.name ? localState.name : ''
    phase.join_type = localState.join_type
    phase.table_A_data_source = localState.table_A_data_source
    phase.join_key_A = localState.join_key_A
    phase.table_B_key = localState.table_B_key
    phase.table_B_data_source = localState.table_B_data_source
    phase.output_column = localState.output_column
    return phase
}

/**
 * Function that returns a DBSource
 * @param {Object} localState - LocalState (form styled) of the DataSource
 * @returns {Object} - database compliant DBSource
 */
export function toDBSource(localState) {
    const dataSource = { ...db_form_setup }
    dataSource.id = localState.id ? localState.id : ''
    if (dataSource.id === '') {
        delete dataSource.id
    }
    dataSource.name = localState.name
    dataSource.connection = localState.connection
    dataSource.connection.port = localState.connection["port@port"]
    delete dataSource.connection["port@port"]
    if (dataSource.connection.id === '') {
        delete dataSource.connection.id
    }
    dataSource.table = localState.table
    dataSource.columns = transformToColumns(localState.columns)
    dataSource.periodicity = {
        start_date: toDateTime(localState.periodicity.start_date), interval: localState.periodicity.interval

    }
    return dataSource
}

/**
 * Function that creates a file (CSV, XLSX, TXT)
 * @param {Object[]} data - Data to export
 * @param {string} [type=xlsx] - File type
 * @param {string} [name=DataExport] - Filename
 * @returns {Object} - database compliant dataSource
 */
export function export_to(data, type = 'xlsx', name = 'DataExport') {
    const table_content = [...data]
    const wb = XLSX.utils.book_new();
    const pub = XLSX.utils.json_to_sheet(table_content);
    XLSX.utils.book_append_sheet(wb, pub, name);
    XLSX.writeFile(wb, `download-data.${type}`);
}

/**
 * Function that returns an array with sanitized data (for tables)
 * @param {Object[]} data - Data to sanitize
 * @param {string[]} notForTable - Array with the key names to avoid (removes the given keys from the objects)
 * @returns {Object[]} - Sanitized data
 */
export function sanitizeInput(data, notForTable) {
    return [...data.map((d) => {
        let j = { ...d }
        for (const key of Object.keys(j)) {
            if (typeof j[key] === 'object') {
                let temp = { ...j[key] }
                for (const n of Object.keys(j[key])) {
                    temp = { ...temp, ...{ [n]: j[key][n] ? j[key][n].toString() : '' } }
                    if (notForTable.includes(n)) {
                        delete temp[n]
                    }
                }
                j = { ...j, [key]: { ...j[key], ...temp } }
                if (notForTable.includes(key)) {
                    delete j[key]
                }
            } else {
                j[key] = j[key] ? j[key].toString() : ''
                if (notForTable.includes(key)) {
                    delete j[key]
                }
            }
        }
        return j
    })]
}

/**
 * Function that sets the state of a table after filtering
 * @param {Object[]} data - Data to filter
 * @param {String[]} notForTable - Array with the key names to avoid (removes the given keys from the objects)
 * @param {string} filterBy - String to filter with
 * @param {function} setState - Function that sets the state
 */
export function filterTable(data, notForTable = [], filterBy, setState) {
    let filteredData = [...data]
    filteredData = filteredData.filter((d) => {
        for (const dKey in d) {
            if (d[dKey]) {
                if (dKey === 'id' && d.id.toString() === filterBy) {
                    return true
                }
                if (dKey !== 'id') {
                    if (typeof d[dKey] === 'object') {
                        Object.keys(d[dKey]).forEach((oKey) => {
                            if (d[dKey][oKey].toString().toLowerCase().includes(filterBy.toLowerCase())) {
                                return true
                            }
                        })
                    } else if (d[dKey].toString().toLowerCase().includes(filterBy.toLowerCase())) {
                        return true
                    }
                }
            }
        }
        return false
    })
    setState(() => sanitizeInput([...filteredData], notForTable))
}

/**
 * Function that resets the filtered table to its original state
 * @param {Object[]} data - Original data
 * @param {String[]} notForTable - Array with the key names to avoid (removes the given keys from the objects)
 * @param {function} setState - Function that sets the state
 */
export function resetTable(data, notForTable = [], setState) {
    setState(() => sanitizeInput([...data], notForTable))
}

/**
 * Function that return if a form is valid
 * @param {Object} localState - The object tha is going to be validated
 * @param {function} setErrorState - Function to set the error state
 * @param {String[]} noValidate - Array with the key names to avoid
 * @returns {boolean}
 */
export function validateForm(localState, setErrorState, noValidate = []) {
    let valid = true
    if (localState) {
        Object.keys(localState).forEach(key => {
            const keyType = extractType(key)
            if (keyType.type) {
                keyType['key'] = keyType['id']
            }
            if (typeof localState[keyType['key']] === "object" && !noValidate.includes(keyType['key'])) {
                Object.keys(localState[keyType['key']]).forEach(inner => {
                    const innerKeyType = extractType(inner)
                    if (innerKeyType.type) {
                        innerKeyType['key'] = innerKeyType['id']
                    }
                    if (!noValidate.includes(innerKeyType['key']) && !validateType(innerKeyType['type'], localState[keyType['key']][innerKeyType['key']])) {
                        setErrorState((state) => ({
                            ...state,
                            [keyType['key']]: {
                                ...state[keyType['key']],
                                [innerKeyType['key']]: `${innerKeyType['key']} no válido`
                            }
                        }))
                        valid = false
                    }
                })
            } else {
                if (!noValidate.includes(keyType['key']) && !validateType(keyType['type'], localState[keyType['key']])) {
                    setErrorState((state) => ({ ...state, [keyType['key']]: `${keyType['key']} no válido` }))
                    valid = false
                }
            }

        })

        if (valid) {
            Object.keys(localState).forEach(key => {
                const keyType = extractType(key)
                if (keyType.type) {
                    keyType['key'] = keyType['id']
                }
                if (typeof localState[keyType['key']] === "object") {
                    Object.keys(localState[keyType['key']]).forEach(inner => {
                        const innerKeyType = extractType(inner)
                        if (innerKeyType.type) {
                            innerKeyType['key'] = innerKeyType['id']
                        }
                        setErrorState((state) => ({
                            ...state,
                            [keyType['key']]: { ...state[keyType['key']], [innerKeyType['key']]: '' }
                        }))
                    })
                } else {
                    setErrorState((state) => ({ ...state, [keyType['key']]: '' }))
                }
            })
        }
    } else {
        return false
    }
    return valid
}

/**
 * Function that returns if a form input is valid (type is the object key)
 * @param type
 * @param value
 * @returns {boolean}
 */
function validateType(type, value) {
    switch (type) {
        case ('url'):
            if (value === '') {
                return false
            }
            return validator.isURL(value);

        case ('email'):
            if (value === '') {
                return false
            }
            return validator.isEmail(value);
        case ('port'):
            if (value === '') {
                return false
            }
            return validator.isPort(value);
        default:
            return value !== '';
    }
}

/**
 *
 * @param phase
 * @param noPrintable
 * @returns {HTMLElement[]}
 */
export function generatePhaseDescription(phase, noPrintable = []) {
    let description = []
    Object.keys(phase).forEach((key, index) => {
        if (typeof phase[key] === "object" && !noPrintable.includes(key)) {
            let innerDescription = []
            Object.keys(phase[key]).forEach((inner, innerIndex) => {
                if (typeof phase[key][inner] !== 'object') {
                    if (!noPrintable.includes(inner) && phase[key][inner] !== '') {
                        innerDescription = [...innerDescription,
                        <div key={innerIndex} className={'phaseDescriptionItem'}><h5
                            className={'key'}>{inner}:</h5> {phase[key][inner]}</div>]
                    }
                }
            })
            if (innerDescription.length > 0) {
                description = [...description,
                <div className={'innerPhaseDescription'} key={index}><h5
                    className={'key'}>{key}: </h5>
                    <div className={'innerItems'}>{innerDescription}</div>
                </div>]
            }
        } else {
            if (!noPrintable.includes(key)) {
                if (phase[key] !== '') {
                    description = [...description, <div key={index} className={'phaseDescriptionItem'}><h5
                        className={'key'}>{key}: </h5>{phase[key]}</div>]
                }
            }
        }
    })

    return description
}

/**
 * Function that return if a form is empty
 * @param {Object} localState - The object tha is going to be validated
 * @param {String[]} noValidate - Array with the key names to avoid
 * @returns {boolean}
 */
export function isFormEmpty(localState, noValidate = []) {
    let empty = true
    if (localState && localState !== '') {
        Object.keys(localState).forEach(key => {
            if (typeof localState[key] === "object" && !noValidate.includes(key)) {
                Object.keys(localState[key]).forEach(inner => {
                    if (!noValidate.includes(inner) && localState[key][inner] !== '') {
                        empty = false
                    }
                })
            } else {
                if (!noValidate.includes(key)) {
                    if (localState[key] !== '') {
                        empty = false
                    }
                }
            }
        })
    } else {
        return true
    }
    return empty
}

/**
 * Function that sets the state after updating the object
 * @param {string} name - Key name to update
 * @param {*} value - New value
 * @param {function} setLocalState - Function to set state
 * @param {function} setErrorState - Function to set error state
 * @param {*} localState - State
 * @param {*} errorState - Error State
 * @param parentErrorName - Set if error state must be updated in child
 */
export function changeInput(name, value, setLocalState, setErrorState, localState = undefined, errorState = undefined, parentErrorName = '') {
    const update = localState ? { ...localState } : {}
    const error = errorState ? { ...errorState } : {}
    const keyType = extractType(name)
    if (keyType.type) {
        keyType['key'] = keyType['id']
    }
    update[keyType['key']] = value
    if (localState && typeof update[keyType['key']] === 'object' && parentErrorName === '') {
        Object.keys(value).forEach((inner) => {
            if (update[keyType['key']][inner] !== '') {
                error[keyType['key']][inner] = ''
            }
        })
    } else {
        if (parentErrorName !== '') {
            error[parentErrorName][keyType['key']] = ''
        } else {
            error[keyType['key']] = ''
        }
    }
    setLocalState((state) => ({ ...state, ...update }))
    setErrorState((state) => ({ ...state, ...error }))
}

/**
 * Extracts the name and type of the key
 * @param name
 * @returns {{name: *, type: *}}
 */
function extractType(name) {
    const id = extractId(name)
    const nameType = id.split('@')
    return { key: nameType[0], type: nameType[1] ? nameType[1] : '', id: id }
}

/**
 * Function that returns the id from a composed string
 * @param {string} id - String with the composed id ({desired id}#{rest})
 * @returns {string}
 */
export function extractId(id) {
    return id && id.length > 0 ? id.split('#')[0] : ''
}

/**
 * Returns id and type separated
 * @param {string} id
 * @returns {{id_part: number, type_part: string}}
 */
export function splitIdandType(id) {
    return id ? { id_part: Number(id)} : { id_part: 0}
}

/**
 * Function that returns a string list from comma separated string
 * @param {string | string[]} commaSeparated - Comma separated column names
 * @returns {string[]}
 */
export function transformToColumns(commaSeparated) {
    return commaSeparated ? !Array.isArray(commaSeparated) ? commaSeparated.split(',') : commaSeparated : []
}

/**
 * Function that returns a comma separated string from an array
 * @param {string[]} columns - Column names
 * @returns {string}
 */
export function arrayToCommaString(columns) {
    let string = columns && columns.length > 0 ? columns : ''
    if (typeof columns === 'object') {
        if (columns.length > 0) {
            string = columns.join(',')
        } else {
            string = ''
        }
    }
    return string;
}

/**
 * Sets file state to initial
 * @param setFileState
 */
export function removeFile(setFileState) {
    setFileState((state) => ({
        ...state, ...{
            filename: '', file: ''
        }
    }))
}

/**
 *  Adds file to file state
 * @param {event} event
 * @param {file[]} files
 * @param {function} setFileState
 */
export function addFile(event, files, setFileState, types) {
    if (files[0].name.includes(types)) {
        const filename = files[0].name
        const file = files[0]
        setFileState((state) => ({ ...state, ...{ filename, file } }))
    }
}

/**
 * Performs file upload
 * @param {Object} fileState
 * @param {function} setSubmitState
 * @param {function} setNewState
 * @param {string} modalId
 * @param {Dispatch<*>} dispatch
 * @param {function} output
 * @param {string} action_type
 */
export function uploadFile(fileState, setSubmitState, setNewState, dispatch, output, action_type = 'postUploader', modalId = '') {
    if (fileState.filename !== '') {
        const data = new FormData();
        data.append('name', fileState.filename);
        data.append('file', fileState.file);
        data.append('periodicity', "@once");
        setSubmitState(() => true)
        dispatch(sourcesActionsRequestThunk({
            type: action_type, id: splitIdandType(fileState.id).id_part, data
        })).then((r) => {
            if (r.error) {
                if (r.payload.http_status_code === 502){
                    dispatch(pushNotification(new NotificationModel('Uploading file failed', `This file has been already uploaded. You can't upload the same file twice`, 'error').toJson()))
                }else{
                    dispatch(pushNotification(new NotificationModel('Uploading file failed', `HTTP error: ${r.payload.http_status_code}, contact administrator. soporte@espossible.com`, 'error').toJson()))
                }
            } else {
                output(fileState, 'updateItem')
            }
            setSubmitState(() => false)
            dispatch(toggleModal(modalId))
            setNewState(() => ({}))
        })
    } else {
        setSubmitState(() => false)
    }
}

/**
 * Function that returns a list of form elements
 * @param {Object[]} elements - A list of objects tha need to  be converted to form inputs
 * @param {function} setLocalState - Function to set state on input change
 * @param {function} setErrorState  - Function to set error state on input change
 * @param localState - local state
 * @param errorState - error state
 * @param inputRef - React ref
 * @param {string} keyVal - String used to generate element id
 * @param {string} parenErrorName - if error state must be updated in parent object
 * @returns {Element[]}
 */
export function formComponents(elements, setLocalState, setErrorState, localState, errorState, inputRef, keyVal, parenErrorName = '') {
    let processedEl = []
    let innerEls = []
    function recursiveFormElementsBuilder(el, setLocalState, setErrorState, localState, errorState, inputRef, keyVal, index, fromGroup = false, innerId = -1, groupItemsCount = 0, parentErrorName) {
        if (el.type === 'group') {
            el.inner.forEach((inner, innerIndex) => recursiveFormElementsBuilder(inner, setLocalState, setErrorState, localState, errorState, inputRef, keyVal, `${index}-${innerIndex}`, true, innerIndex, el.inner.length, parentErrorName))
        } else {
            if (fromGroup) {
                if (innerId < groupItemsCount - 1) {
                    innerEls.push(inputTypes[el.type](index, el, setLocalState, setErrorState, localState, errorState, inputRef, keyVal, parentErrorName))
                } else {
                    innerEls.push(inputTypes[el.type](index, el, setLocalState, setErrorState, localState, errorState, inputRef, keyVal, parentErrorName))
                    processedEl.push(<div className={'inline-form'} key={'inner-group#' + index}>{innerEls}</div>)
                    innerEls = []
                }
            } else {
                processedEl.push(inputTypes[el.type](index, el, setLocalState, setErrorState, localState, errorState, inputRef, keyVal, parentErrorName))
            }
        }
    }

    elements.forEach((el, index) => {
        recursiveFormElementsBuilder(el, setLocalState, setErrorState, localState, errorState, inputRef, keyVal, index, false, -1, 0, parenErrorName)
    })
    return processedEl
}


/**
 * Form input types
 * @type {{select: (function(*, *, *, *, *, *)), text: (function(*, *, *, *, *, *))}}
 */
const inputTypes = {
    text: (index, el, setLocalState, setErrorState, localState, errorState, inputRef, keyVal, parentErrorName) => {
        return <TextInput
            className={'form-input'}
            key={index}
            type={el.subType ? el.subType : el.type}
            id={`${el.id}#${keyVal}`}
            invalidText={el.invalidText}
            labelText={el.labelText}
            placeholder={el.placeholder}
            value={el.value}
            max={el.max}
            min={el.min}
            onChange={(event) => changeInput(event.target.id, event.target.value, setLocalState, setErrorState, localState, errorState, el.parentErrorName ? el.parentErrorName : parentErrorName)}
            ref={inputRef}
            invalid={el.invalid !== '' && el.invalid !== 0}
            disabled={el.disabled !== "undefined" ? el.disabled : false}
        />
    },
    select: (index, el, setLocalState, setErrorState, localState, errorState, inputRef, keyVal, parentErrorName) => {
        return <Select
            className={'form-input'}
            key={index}
            helperText={el.helperText}
            id={`${el.id}#${keyVal}`}
            labelText={el.labelText ? el.labelText : "Seleccione una opción"}
            size="md"
            onChange={(event) => changeInput(event.target.id, event.target.value, setLocalState, setErrorState, localState, errorState, el.parentErrorName ? el.parentErrorName : parentErrorName)}
            value={el.value}
            disabled={el.disabled !== "undefined" ? el.disabled : false}
        >
            {el.types.map((type) => {
                return <SelectItem key={type.key}
                    text={`${type.name.toUpperCase()}`}
                    value={type.key}
                />
            })}
        </Select>
    },
    combo: (index, el, setLocalState, setErrorState, localState, errorState, inputRef, keyVal, parentErrorName) => {
        return <ComboBox
            className={'form-input'}
            key={v4()}
            titleText={el.labelText}
            helperText={el.placeholder}
            id={`${el.id}#${keyVal}`}
            size="lg"
            onChange={(e) => changeInput(`${el.id}#${keyVal}`, (e.selectedItem ? e.selectedItem.text : ''), setLocalState, setErrorState, localState, errorState, el.parentErrorName ? el.parentErrorName : parentErrorName)}
            ref={inputRef}
            items={el.types}
            itemToString={(item) => (item ? item.text : '')}
            invalid={el.invalid !== ''}
            invalidText={el.invalidText}
            selectedItem={el.types.find(item => item.text === el.value) ? el.types.find(item => item.text === el.value) : ''}
            disabled={el.disabled !== "undefined" ? el.disabled : false}
        />
    },
    multiselect: (index, el, setLocalState, setErrorState, localState, errorState, inputRef, keyVal, parentErrorName) => {
        return <FilterableMultiSelect
            className={'form-input'}
            key={v4()}
            titleText={el.labelText}
            helperText={el.placeholder}
            label={el.labelText}
            id={`${el.id}#${keyVal}`}
            size="lg"
            onChange={(e) => { changeInput(`${el.id}#${keyVal}`, (e.selectedItems ? e.selectedItems.map((e) => e.id).toString() : ''), setLocalState, setErrorState, localState, errorState, el.parentErrorName ? el.parentErrorName : parentErrorName) }}
            ref={inputRef}
            items={el.types}
            itemToString={(item) => (item ? item.text : '')}
            invalid={el.invalid !== ''}
            invalidText={el.invalidText}
            initialSelectedItems={getMultiselectSelectedItems(el.value, el.types)}
            disabled={el.disabled !== "undefined" ? el.disabled : false}
        />
    },
}

function getMultiselectSelectedItems(items, types) {
    return [...types.filter((type) => items.includes(type.id))]
}

/**
 * Returns Sources adapted to local
 * @param datoolStore
 * @returns {Object[]}
 */
export function sourcesToLocal(sources) {
    let result;
    if ((sources.downloaders && sources.downloaders.length > 0) || (sources.db_sources && sources.db_sources.length > 0) || (sources.file_uploads && sources.file_uploads.length > 0)) {
        result = [...sources.downloaders.map((a) => dataSourceToLocal(a, 'downloader'))]
        result = [...result, ...sources.db_sources.map((a) => dataSourceToLocal(a, 'db'))]
        result = [...result, ...sources.file_uploads.map((a) => dataSourceToLocal(a, 'file'))]
        // result = [...uniqueIdForTable(result)]
        return result
    }
}


/**
 * Function that returns sources with their id updated with id+type
 * @param sources
 * @returns {Object[]}
 */
function uniqueIdForTable(sources) {
    let processed_sources = [...sources]
    processed_sources.forEach((e) => {
        e.id = `${e.id}@${e.global_type}`
    })
    return processed_sources
}

/**
 * Actions for creating, deleting and updating phases
 * @type {{addPhase: actions.addPhase, deletePhase: actions.deletePhase, editPhase: actions.editPhase}}
 */
export const actions = {
    addPhase: (data, localState, setLocalState) => {
        setLocalState((state) => ([...state, data]))
    },
    editPhase: (data, localState, setLocalState, setSelectedPhase) => {
        let newData = localState.map((e) => {
            if (e.tempId !== '' && e.tempId === data.tempId) {
                return { ...e, ...data }
            } else if (e.id !== '' && e.id === data.id) {
                return { ...e, ...data }
            }
            return e
        }
        )
        setLocalState(() => ([...newData]))
        setSelectedPhase(() => '')
    },
    deletePhase: (data, localState, setLocalState, setSelectedPhase) => {
        let newData = localState.filter((e) => {
            if (e.tempId !== '' && e.tempId === data.tempId) {
                return false
            } else if (e.id !== '' && e.id === data.id) {
                return false
            }
            return true
        })
        setLocalState(() => ([...newData]))
        setSelectedPhase(() => '')
    },
}

/**
 * Changes order of items
 * @param returnData
 * @param setLocalState
 */
export function changeOrder(returnData, setLocalState) {
    let oldPosItemOrder = returnData.oldPositionItem.order
    let newPosItemOrder = returnData.newPositionItem.order
    setLocalState((state) => (
        [...state.map((e) => {
            if (e.order === oldPosItemOrder) {
                let n = { ...e }
                n.order = newPosItemOrder
                return n
            }
            if (e.order === newPosItemOrder) {
                let n = { ...e }
                n.order = oldPosItemOrder
                return n
            }
            return e
        })]
    )
    )
}

/**
 * Returns a string with the specific keys to be validated
 * @param localState
 * @returns {string[]}
 */
export function validationList(localState) {
    let onlyValidate = []
    switch (localState.sanitization_type) {
        case 'TYPE ENFORCEMENT':
            onlyValidate = [...onlyValidate, 'enforce_type']
            switch (localState.parameters.enforce_type) {
                case 'DATE':
                    onlyValidate = [...onlyValidate, 'date_format']
                    break
                case 'CATEGORICAL':
                    switch (localState.parameters.categorical_type) {
                        case 'CUT POINTS':
                            onlyValidate = [...onlyValidate, 'cut_points', "labels"]
                            break
                        default:
                            onlyValidate = [...onlyValidate, 'num_buckets']
                            break
                    }
                    break
            }
            break
        case 'REPLACE NA':
            onlyValidate = [...onlyValidate, 'replacement_type']
            switch (localState.parameters.replacement_type) {
                case 'INTERPOLATE':
                    onlyValidate = [...onlyValidate, 'index_column']
                    break
                case 'LITERAL':
                    onlyValidate = [...onlyValidate, 'literal_NA']
                    switch (localState.parameters.data_type) {
                        case 'DATE':
                            onlyValidate = [...onlyValidate, 'date_format']
                            break
                    }
                    break
            }
            break
        case 'REPLACE VALUES':
            onlyValidate = [...onlyValidate, 'replacement_type', 'is_integer', 'comparison_type_replace', 'literal_replace']
            switch (localState.parameters.replacement_type) {
                case 'INTERPOLATE':
                    onlyValidate = [...onlyValidate, 'index_column']
                    break
            }
            break
        /* case 'FILTER':
            onlyValidate = [...onlyValidate, 'replacement_type', 'value_to_compare']
            switch (localState.parameters.filter_data_type) {
                case 'DATE':
                    onlyValidate = [...onlyValidate, 'date_format']
                    break
            }
            break */
        case 'OUTLIER':
            onlyValidate = [...onlyValidate, 'remove_outliers_groups', 'validation_delete']
            break
        case 'EXPLODE':
            onlyValidate = [...onlyValidate, 'explode_type']
            switch (localState.parameters.explode_type) {
                case 'EXPLODE_REGEX':
                    onlyValidate = [...onlyValidate, 'explode_regexp']
                    break
                case 'EXPLODE_AUX_COLUMN':
                    onlyValidate = [...onlyValidate, 'explode_join_key', 'explode_dependent_columns']
                    break
            }
            break
    }
    return onlyValidate
}

/**
 * Returns a string with the specific keys to be validated
 * @param localState
 * @returns {string[]}
 */
export function validationListKDD(localState) {
    let onlyValidate = []
    switch (localState.kdd_type) {
        case 'AGGREGATION':
            onlyValidate = [...onlyValidate, 'aggregation_operation', 'confidence_interval']
            break
    }
    switch (localState.kdd_type) {
        case 'CORRELATION':
            onlyValidate = [...onlyValidate, 'correlation_type', 'output_file']
            break
    }
    switch (localState.kdd_type) {
        case 'HYPOTESIS TEST':
            onlyValidate = [...onlyValidate, 'hypothesis_test', 'statistic_estimator', 'value_hypotesis']
            break
    }
    switch (localState.kdd_type) {
        case 'COUNT FILTER':
            onlyValidate = [...onlyValidate, 'type_count_filter', 'value_count_filter']
            break
    }
    switch (localState.kdd_type) {
        case 'COLUMNS OPERATION':
            onlyValidate = [
                ...onlyValidate,
                'co_input_column_2',
                'co_aritmetic_value',
                'co_value',
                'co_aritmetic_type',
            ]
            switch (localState.parameters_kdd.co_aritmetic_type) {
                case 'Simple':
                    onlyValidate = [
                        ...onlyValidate,
                        'co_aritmetic_operation'
                    ]
                    break
                case 'Compleja':
                    onlyValidate = [
                        ...onlyValidate,
                        'co_matriz_operation',
                        'co_input_array'
                    ]
                    break
            }
            break
    }
    switch (localState.kdd_type) {
        case 'DATE OPERATIONS':
            onlyValidate = [...onlyValidate, 'date_operation']
            let d_cut_point = localState.parameters_kdd.date_cut_points
            console.error(d_cut_point)
            if (d_cut_point !== 0 && d_cut_point !== '' && d_cut_point !== '0') {
                onlyValidate = [
                    ...onlyValidate,
                    'date_cut_points',
                    'date_label_array'
                ]
            }
            break
    }
    switch (localState.kdd_type) {
        case 'REDUCE DATASET':
            onlyValidate = [...onlyValidate, 'reduce_select_columns', 'reduce_drop_duplicate']
            break
    }
    switch (localState.kdd_type) {
        case 'CUSTOM KDD':
            onlyValidate = [...onlyValidate, 'custom_code']
            break
    }
    return onlyValidate
}

/**
 * Periodicity default object
 * @type {{interval: string, start_date: string}}
 */
const periodicity = {
    start_date: '', interval: ''
}
/**
 * DataSource  default object
 * @type {{name: string, periodicity: {interval: string, start_date: string}, id: string, type: string, url: string, global_type: string}}
 */
export const dataSources = {
    id: '', name: '', "url@url": '', periodicity, file_type: '', global_type: ''
}
/**
 * DBConnection default object
 * @type {{db_type: string, password: string, db_name: string, port: string, host: string, id: string, user: string}}
 */
export const db_con_form_setup = {
    id: '', host: '', "port@port": '', user: '', password: '', db_name: '', db_type: '',
}
/**
 * DBSource default object
 * @type {{columns: string, name: string, periodicity: {interval: string, start_date: string}, connection: {db_type: string, password: string, db_name: string, port: string, host: string, id: string, user: string}, id: string, table: string}}
 */
export const db_form_setup = {
    id: '', name: '', connection: db_con_form_setup, table: '', columns: '', periodicity,
}
/**
 * JoinPhase default object
 * @type {{table_A_data_source: string, join_type: string, table_B_key: string, join_key_A: string, id: string, tempId: string, table_B_data_source: string, output_column: string, global_type: string}}
 */
export const joinPhase = {
    id: '', tempId: '', name: '', table_A_data_source: '', order: '', join_type: '', join_key_A: '', table_B_key: '', table_B_data_source: '', output_column: '',
}

/**
 * Parameters default object
 * @type {{comparison_type_replace: string, explode_join_key: string, interpolation_index_column: string, remove_outliers_groups: string, validation_delete: string, explode_dependent_columns: string, explode_regexp: string, num_buckets: number, aux_column: string, explode_aux_column: string, categorical_type: string, is_integer: string, index_column: string, labels: string, cut_points: number, literal_value: string, enforce_type: string, explode_type: string, filter_type: string, data_type: string, literal_data_type: string, date_format: string, filter_data_type: string, replacement_type: string, value_to_compare: string, literal_replace: string, literal_NA: string}}
 */
export const parameters = {
    enforce_type: '',
    explode_type: '',
    labels: '',
    cut_points: 0,
    num_buckets: 0,
    date_format: '',
    replacement_type: '',
    index_column: '',
    literal_value: '',
    data_type: '',
    literal_data_type: '',
    interpolation_index_column: '',
    remove_outliers_groups: '',
    validation_delete: '',
    is_integer: '',
    comparison_type_replace: '',
    value_to_compare: '',
    literal_replace: '',
    literal_NA: '',
    filter_type: '',
    filter_data_type: '',
    aux_column: '',
    explode_regexp: '',
    explode_aux_column: '',
    explode_join_key: '',
    explode_dependent_columns: '',
    categorical_type: '',
}
/**
 * SanitizePhase default object
 * @type {{output_column: string, add_modified_indicator_column: string, sanitization_type: string, id: string, tempId: string, target_column: string, parameters: {comparison_type_replace: string, explode_join_key: string, interpolation_index_column: string, remove_outliers_groups: string, validation_delete: string, explode_dependent_columns: string, explode_regexp: string, num_buckets: number, aux_column: string, explode_aux_column: string, categorical_type: string, is_integer: string, index_column: string, labels: string, cut_points: number, literal_value: string, enforce_type: string, explode_type: string, filter_type: string, data_type: string, literal_data_type: string, date_format: string, filter_data_type: string, replacement_type: string, value_to_compare: string, literal_replace: string, literal_NA: string}, order: string}}
 */
export const sanitizePhase = {
    id: '',
    name: '',
    tempId: '',
    target: '',
    target_column: '',
    sanitization_type: '',
    output_column: '',
    add_modified_indicator_column: '',
    order: '',
    parameters
}

/**
 * Parameters_kdd default object
 * @type {{aggregation_operation: string, confidence_interval: string, correlation_type: string, output_file: string, label_column_1: string, input_column_2: string, label_column_2: string, hypothesis_test: string, statistic_estimator: string, value_hypotesis: string, type_count_filter: string, value_count_filter: string, date_format_countfilt: string, co_input_column_2: string, co_aritmetic_value: string, co_value: string, co_aritmetic_type: string, co_aritmetic_operation: string, co_matriz_operation: string, co_input_array: string, date_operation: string, date_is_categorical: string, date_cut_points: number, date_label_array: string, reduce_select_columns: string, reduce_drop_duplicate: string, custom_code: string}}
 */
export const parameters_kdd = {
    aggregation_operation: '',
    confidence_interval: '',
    correlation_type: '',
    output_file: '',
    label_column_1: '',
    input_column_2: '',
    label_column_2: '',
    hypothesis_test: '',
    statistic_estimator: '',
    value_hypotesis: '',
    type_count_filter: '',
    value_count_filter: '',
    date_format_countfilt: '',
    co_input_column_2: '',
    co_aritmetic_value: '',
    co_value: '',
    co_aritmetic_type: '',
    co_aritmetic_operation: '',
    co_matriz_operation: '',
    co_input_array: '',
    date_operation: '',
    date_is_categorical: '',
    date_cut_points: 0,
    date_label_array: '',
    reduce_select_columns: '',
    reduce_drop_duplicate: '',
    custom_code: '',
}
/**
 * KddPhase default object
 * @type {{kdd_type: string, id: string, tempId: string, input_column: string, group_column: string, output_column: string, parameters_kdd: {aggregation_operation: string, confidence_interval: string, correlation_type: string, output_file: string, label_column_1: string, input_column_2: string, label_column_2: string, hypothesis_test: string, statistic_estimator: string, value_hypotesis: string, type_count_filter: string, value_count_filter: string, date_format_countfilt: string, co_input_column_2: string, co_aritmetic_value: string, co_value: string, co_aritmetic_type: string, co_aritmetic_operation: string, co_matriz_operation: string, co_input_array: string, date_operation: string, date_is_categorical: string, date_cut_points: number, date_label_array: string, reduce_select_columns: string, reduce_drop_duplicate: string, custom_code: string}, order: string}}
 */
export const kddPhase = {
    id: '',
    name: '',
    tempId: '',
    input_column: '',
    kdd_type: '',
    order: '',
    group_column: '',
    output_column: '',
    parameters_kdd
}

/**
 *  Pipeline default object
 * @type {{join_steps: joinPhase[], name: string, id: string, source: string, kdd_steps: kddPhase[], sanitization_steps: sanitizePhase[]}}
 */
export const pipelineDefault = {
    id: '', name: '', sources: '', join_steps: [], sanitization_steps: [], kdd_steps: []
}

/**
 * Data types admitted in sanitizations for combo boxes
 */
export const data_types = [
    { id: 'STRING', text: 'STRING' },
    { id: 'INTEGER', text: 'INTEGER' },
    { id: 'FLOAT', text: 'FLOAT' },
    { id: 'BOOLEAN', text: 'BOOLEAN' },
    { id: 'CATEGORICAL', text: 'CATEGORICAL' },
    { id: 'DATE', text: 'DATE' }
]

/**
 * Data types admitted in sanitizations for select
 */
export const data_types_select = [
    { key: 'STRING', name: 'STRING' },
    { key: 'INTEGER', name: 'INTEGER' },
    { key: 'FLOAT', name: 'FLOAT' },
    { key: 'BOOLEAN', name: 'BOOLEAN' },
    { key: 'CATEGORICAL', name: 'CATEGORICAL' },
    { key: 'DATE', name: 'DATE' },
]