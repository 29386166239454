import ReactDOM from 'react-dom'

const Index = ({children}) => {
    return (
        <>
            {typeof document === 'undefined'
                ? null
                : ReactDOM.createPortal(
                    children,
                    document.body
                )}
        </>
    );
};
export default Index
