import axios from "axios";
import { BASE_BACKEND_URL } from "../constants";
import { tokenRefreshThunk } from "../store/reducers/userReducer";
import jwt_decode from 'jwt-decode';

export const getAxiosPublic = () => axios.create({ baseURL: BASE_BACKEND_URL });

export const getAxiosPrivate = (store, headers = {}) => {
    let axiosPrivate = axios.create({ baseURL: BASE_BACKEND_URL });
    axiosPrivate.interceptors.request.use(
        async (config) => {
            let userState = store?.getState().user
            if (!checkToken(userState.token_access))
                await store?.dispatch(tokenRefreshThunk());

            if (config?.headers) {
                config.headers["authorization"] = `Bearer ${store?.getState()?.user?.token_access
                    }`;
            }
            if (Object.keys(headers).length > 0) {
                config.headers = { ...config.headers, ...headers }
            }
            return config;
        },
        (error) => {
            console.log(error)
            return Promise.reject(error);
        }
    );
    return axiosPrivate;
}


export const checkToken = (access_token) => {
    if (access_token) {
        try {
            return jwt_decode(access_token).exp > (Date.now().valueOf() / 1000)
        } catch (err) {
            console.error(err)
            return false;
        }
    }
    return false
}
