const Index = ({chart}) => {
    if (chart)
        return (
            <div className='counter-panel'>
                <h4 className="title cds--data-table-header__title">{chart.options.title}</h4>
                <h5 className=" subtitle">{chart.options.subtitle}</h5>
                <div className="value-container">
                    <h1 className="value">{chart.data.value}</h1>
                </div>
            </div>
        )
    else {
        return (
            <div>

            </div>
        )
    }

}


export default Index
