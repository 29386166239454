import {Download} from "@carbon/icons-react";
import {TableToolbarAction, TableToolbarContent, TableToolbarMenu, TableToolbarSearch} from "@carbon/react";
import {export_to, filterTable, resetTable} from "../../../Utils/utils.js";

const Index = ({data, notForTable, setState}) => {
    const filtered = [...data]
    const old_data = [...data]
    const notForTableInner = [...notForTable]
    return (
        <TableToolbarContent>
            <TableToolbarSearch tabIndex={0}
                                onChange={(e) => filterTable(filtered, notForTableInner, e.target.value, setState)}
                                onClear={() => resetTable(old_data, notForTableInner, setState)}
            />
            <TableToolbarMenu iconDescription={'Más'}>
                <TableToolbarAction onClick={() => export_to(filtered)}
                                    className={'toolbar-action'}>
                    <Download size={24}/>
                    <span className='download-csv'>XLSX</span>
                </TableToolbarAction>
                <TableToolbarAction onClick={() => export_to(filtered, 'csv')}
                                    className={'toolbar-action'}>
                    <Download size={24}/>
                    <span className='download-csv'>CSV</span>
                </TableToolbarAction>
                <TableToolbarAction onClick={() => export_to(filtered, 'txt')}
                                    className={'toolbar-action'}>
                    <Download size={24}/>
                    <span className='download-csv'>TXT</span>
                </TableToolbarAction>
            </TableToolbarMenu>
        </TableToolbarContent>)
}

export default Index
