import { Button, InlineLoading, TextInput } from "@carbon/react";
import { useDispatch, useSelector } from 'react-redux'
import { tokenRequestThunk, updateErrorState, updateUsernameState } from "../../store/reducers/userReducer"
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import { pushNotification } from "../../store/reducers/notificationsReducer";
import { NotificationModel } from "../../Models/Notification";
import { checkToken } from "../../apiClient/common";
import { ArrowRight, LogoFacebook, LogoGithub, LogoLinkedin } from "@carbon/icons-react";


const Index = () => {
    const [invalid_dictionary, setinvalidDictionary] = useState({
        'invalid_username': false,
        'invalid_password': false,
        invalidText_username: '',
        invalidText_password: '',
        disabled: true
    });
    const [loginForm, setData] = useState({ username: "", password: "" })
    const inputRef = useRef()

    const userState = useSelector((state) => state.user)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() => {
        if (location.pathname === '/login' && checkToken(userState.token_refresh)) {
            navigate("/dashboard")
        }
    }, [])
    return (
        <div className="login-screen">
            <div className="background-image-container"></div>
            <div id="login-panel" className='login-panel'>
                <div className="login-container">
                    <div>
                        <div id="login-header">
                        <h1>Log in</h1>
                            <p>Don't have an account? Contact <a href="mailto:soporte@colmenadata.com"
                                className="pointer">us</a></p>
                        </div>
                        {invalid_dictionary.disabled}
                        <TextInput
                            id="login-username-field"
                            className="login-form-input"
                            invalid={invalid_dictionary.invalid_username}
                            invalidText={invalid_dictionary.invalidText_username}
                            labelText="Username"
                            placeholder="Username"
                            value={loginForm.username}
                            name="username"
                            onChange={(e) => checkInputs(e)}
                            autoFocus
                            ref={inputRef}
                        />
                        <TextInput
                            id="login-password-field"
                            className="login-form-input"
                            invalid={invalid_dictionary.invalid_password}
                            invalidText={invalid_dictionary.invalidText_password}
                            labelText="Password"
                            placeholder="******"
                            type="password"
                            name="password"
                            value={loginForm.password}
                            onChange={(e) => checkInputs(e)}
                            onKeyDown={(e) => doLoginKey(e)}

                        />

                        {!userState.loading &&
                            <Button
                                className="login-form-elm"
                                kind="primary"
                                onClick={doLogin}>
                                <h4>Log in</h4>
                                <ArrowRight size={24} />
                            </Button>
                        }
                        {userState.loading &&
                            <InlineLoading
                                status='active'
                                iconDescription="Active loading indicator"
                                description="Logging in..."
                            />
                        }
                        {userState.error && <p className="error">{checkError(userState.error)}</p>}
                    </div>
                </div>
            </div>
        </div>
    )
    function checkError(userStateError){
        if (userStateError === "http_error_401"){
            return ""
        }else{
            return "Error: " + userStateError.toString()
        }
    }

    function doLoginKey(e) {
        if (e.key === "Enter") {
            doLogin()
        }
    }

    function doLogin() {
        let loginData = loginForm
        validate()
        if (loginData.username !== '' && loginData.password !== '') {
            dispatch(updateErrorState())
            dispatch(tokenRequestThunk(loginForm)).then((e) => {
                if (!e.error) {
                    dispatch(updateUsernameState(loginData.username))
                    navigate('/dashboard')
                } else {

                    let newState = {
                        'invalid_username': true,
                        'invalid_password': true,
                        invalidText_username: 'Bad username or password.',
                        invalidText_password: 'Bad username or password.',
                    }
                    if (e.payload.http_status_code !== '') {
                        newState = errorSwitch(e.payload.http_status_code.toString())
                        if (!e.payload.http_status_code.toString().includes('4')) {
                            dispatch(pushNotification(new NotificationModel('Login error',
                                `HTTP error: ${e.payload.http_status_code}, contact administrator. soporte@espossible.com`,
                                'error').toJson()))
                        }
                    }
                    setinvalidDictionary(() => (
                        { ...invalid_dictionary, ...newState }
                    ))
                    inputRef.current.focus();
                }
            }).catch((e) => {
                console.log("Error: ", e);
            })
        }
    }

    function checkInputs(e) {
        setData({ ...loginForm, [e.target.name]: e.target.value })
        const state = {
            'invalid_username': e.target.name === "username" ? e.target.value === '' : false,
            'invalid_password': e.target.name === "password" ? e.target.value === '' : false,
            invalidText_username: e.target.name === "username" && e.target.value === '' ? 'Required username.' : '',
            invalidText_password: e.target.name === "password" && e.target.value === '' ? 'Required password.' : '',
        }
        setinvalidDictionary({
            ...invalid_dictionary, ...state
        })

        dispatch(updateErrorState())
    }

    function validate() {
        const newState = {
            'invalid_username': loginForm.username === '',
            'invalid_password': loginForm.password === '',
            invalidText_username: loginForm.username === '' ? 'Required username.' : '',
            invalidText_password: loginForm.password === '' ? 'Required password.' : '',
        }

        setinvalidDictionary({
            ...invalid_dictionary, ...newState
        })
    }

    function errorSwitch(error) {
        if (error.includes('4')) {
            return {
                'invalid_username': true,
                'invalid_password': true,
                invalidText_username: 'Bad username or password..',
                invalidText_password: 'Bad username or password..',
            }
        } else {
            return {
                'invalid_username': false,
                'invalid_password': false,
                invalidText_username: '',
                invalidText_password: '',
            }
        }
    }
}

export default Index
