import {createSlice} from '@reduxjs/toolkit'


export const notificationsReducer = createSlice({
    name: 'notification',
    initialState: {
        notifications: [],
    },
    reducers: {
        pushNotification: (state, action) => {
            state.notifications = [...state.notifications, action.payload]
        },
        removeNotification: (state, action) => {
            state.notifications = [...state.notifications.filter((e) => e.id !== action.payload.id)]
        },
        updateNotification: (state, action) => {
            if (action.payload) {
                state.notifications[state.notifications.findIndex((e) => e.id === action.payload.id)] = {...action.payload}
            }
        },
        clearNotifications: (state) => {
            state.notifications = []
        }
    }
})

export const {pushNotification, removeNotification, updateNotification} = notificationsReducer.actions
export default notificationsReducer.reducer
