import Notification from "../../components/Notification";
import {useSelector} from "react-redux";

const Index = () => {
    const notifications = useSelector((state) => state.notifications)
    return (
        <div id="notifications-container" className="notifications-container">
            {notifications.notifications.map((notification) => {
                    if (!notification.viewed) {
                        return <Notification key={notification.id}
                                             notification={notification}
                        ></Notification>
                    }
                    return ''
                }
            )
            }
        </div>
    )


}

export default Index
