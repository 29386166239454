import { Content } from "@carbon/react";
import { useEffect } from "react";
import { useSelector } from 'react-redux'

import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { checkToken } from "./apiClient/common";
import './App.scss';
import RouteGuard from "./Guards/RouteGuard";
import FooterLayout from "./Layouts/Footer"
import HeaderLayout from "./Layouts/Header"
import NotificationsLayout from "./Layouts/NotificationsLayout";
import Dashboard from "./Pages/Dashboard";

import Login from "./Pages/Login";

function App() {
    const userState = useSelector((state) => state.user)
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (location.pathname !== "/login" && !checkToken(userState.token_refresh)) {
            navigate('/login')
        }
    })

    return (
        <div className="main-container">
            <HeaderLayout />
            <NotificationsLayout></NotificationsLayout>
            <Content className="container inner-main-container">
                <Routes>
                    <Route exact path="/login" element={<Login />} />
                    <Route path='/*' element={
                        <RouteGuard userState={userState}>
                            <Routes>
                                <Route path="/" element={<Navigate to="/dashboard" />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route exact path="/*"
                                    element={<Navigate to="/dashboard" />} />
                            </Routes>
                        </RouteGuard>

                    }>
                    </Route>
                </Routes>
            </Content>
            <FooterLayout />
        </div>
    );
}

export default App;
