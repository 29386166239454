import { v4 as uuid } from 'uuid';

export class NotificationModel {
    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get viewed() {
        return this._viewed;
    }

    set viewed(value) {
        this._viewed = value;
    }

    get caption() {
        return this._caption;
    }

    set caption(value) {
        this._caption = value;
    }

    get title() {
        return this._title;
    }

    set title(value) {
        this._title = value;
    }

    get subtitle() {
        return this._subtitle;
    }

    set subtitle(value) {
        this._subtitle = value;
    }

    get timeout() {
        return this._timeout;
    }

    set timeout(value) {
        this._timeout = value;
    }

    get kind() {
        return this._kind;
    }

    set kind(value) {
        this._kind = value;
    }


    constructor(title, subtitle, kind = 'info', timeout = 5000) {
        this._title = title;
        this._subtitle = subtitle;
        this._timeout = timeout;
        for (const knd of Object.keys(Kind)) {
            if (knd === kind) {
                this._kind = kind;
                break
            } else {
                this._kind = 'info';
            }
        }
        this._caption = this.getCurrentTime()
        this._viewed = false
        this._id = uuid()
    }

    toJson() {
        return {
            id: this.id,
            title: this.title,
            subtitle: this.subtitle,
            timeout: this.timeout,
            kind: this.kind,
            caption: this.caption,
            viewed: this.viewed
        }
    }

    getCurrentTime() {
        const today = new Date()
        return ((today.getHours() < 10) ? "0" : "") + today.getHours() + ":" + ((today.getMinutes() < 10) ? "0" : "") + today.getMinutes() + ":" + ((today.getSeconds() < 10) ? "0" : "") + today.getSeconds();
    }
}

class Kind {
    static info = new Kind('info');
    static error = new Kind('error');
    static success = new Kind('success');
    static warning = new Kind('warning');

    constructor(name) {
        this._name = name;
    }

    get name() {
        return this._name;
    }

    set name(value) {
        this._name = value;
    }
}
