import React from 'react';
import {Navigate} from "react-router-dom";
import {checkToken} from "../apiClient/common";

const RouteGuard = ({children, userState}) => {
    if (!checkToken(userState.token_refresh)) {
        return <Navigate to="/login"/>;
    }
    return children;
}
export default RouteGuard;
